(function($) {
	
	'use strict';
	
	const Wishlist = {
		
		init: function() {
			let self = this;
            
            if (typeof wishlist_vars == 'undefined') { return; }

            self.$body           = $('body');
            self.isLoggedIn      = (self.$body.hasClass('logged-in')) ? true : false;
            self.wishlistIds     = [];
            self.cookieExpires   = parseInt(wishlist_vars.wlCookieExpires);
            self.IDsUpdateAjax   = false;
            self.wishlistId      = false;
            self.popupSuccesRes  = false;
            self.autoHideNotify  = false;
            self.closeBtnPopup   = false;

            if(document.querySelector('.wishlist-popup-close')) {
                self.closeBtnPopup = document.querySelector('.wishlist-popup-close');
            }

            self.setUp();

			$(document).on('click', '.wishlist-button', function(e) {
				e.preventDefault();

                if(!self.popupSuccesRes) {
                    self.thisBtnToggle = this;
                }
               
                if (wishlist_vars.wlLoginRequire != '0' && ! self.$body.hasClass('logged-in')) {
                        self.showWishlistWidgetPopup();
                    return;
                }
                
				if (self.IDsUpdateAjax) {
                    self.IDsUpdateAjax.abort(); 
                    self.IDsUpdateAjax = false;
				}
                
                self.buttonToggle(this);
			});
            
            let $wishlistTable = $('#wishlist-table');
            if ($wishlistTable.length) {
				$wishlistTable.find('.wishlist-remove').on('click', function(e) {
					e.preventDefault();
					let $this = $(this);
					if ($this.hasClass('clicked')) { return; }
					$this.addClass('clicked');
					self.wishlistItemRemove($this, $wishlistTable);
				});
            }
		},
        
        setUp: function() {
            let self = this;
            
            if (self.isLoggedIn) { 
        
                $.ajax({
                    type: 'POST',
                    url: wishlist_vars.ajaxUrl,
                    data: { action: 'wishlist_get_ids' },
                    dataType: 'json',
                    cache: false,
                    headers: {'cache-control': 'no-cache'},
                    success: function(json) {
                        if (json.ids) {
                            let idsArray = json.ids;

                            self.wishlistIds = idsArray;
                            Cookies.set('wishlist-ids', idsArray, { expires: self.cookieExpires });
                        
                            self.buttonsSetState();
                        }
                    }
                });
            } else {
                $('.wishlist-toggle').removeClass('added');
            }
        },
        
        IDsUpdate: function() {
            let self = this;
            
            if (self.isLoggedIn) {

                let ids = JSON.stringify(self.wishlistIds);

                self.IDsUpdateAjax = $.ajax({
					type: 'POST',
					url: wishlist_vars.ajaxUrl,
					data: {
						action: 'wishlist_update_ids',
                        nonce: wishlist_vars.wlNonce,
                        ids: ids
					},
					dataType: 'json',
					cache: false,
					headers: {'cache-control': 'no-cache'},
					complete: function() {
                        self.IDsUpdateAjax = false;
                    }
				});
            }
            
            Cookies.set('wishlist-ids', self.wishlistIds, { expires: self.cookieExpires });
        },
        
        buttonsSetState: function() {
            let self = this;
            
            for (let i = 0; i < self.wishlistIds.length; i++) { 
                $('#wishlist-item-' + self.wishlistIds[i] + '-button').addClass('added');
            }
            
            self.$body.addClass('wishlist-show-buttons');
        },
        
        buttonSetState: function(productId, isAdding) {
            let self = this,
                $buttons = $('.wishlist-item-' + productId + '-button'); 

            if (isAdding) {
                $buttons.addClass('added');
                $buttons.attr('title', wishlist_vars.wlButtonTitleRemove);
                self.$body.trigger('wishlist_added_item');
                self.showWishlistWidgetPopupSuccess(isAdding, productId);
            } else {
                $buttons.removeClass('added');
                $buttons.attr('title', wishlist_vars.wlButtonTitleAdd);
                self.$body.trigger('wishlist_removed_item');
                self.showWishlistWidgetPopupSuccess(isAdding, productId);
            }
        },
        
        buttonToggle: function(button) {
            let self = this,
                productId = $(button).data('product-id'),
                isAdding = true;

            let productIdIndex = $.inArray(productId, self.wishlistIds);

            if (productIdIndex == -1) {

                self.wishlistIds.unshift(productId);

            } else {

                self.wishlistIds.splice(productIdIndex, 1);
                
                isAdding = false;

            }
            
            self.IDsUpdate();
            self.buttonSetState(productId, isAdding);
        },
        
        wishlistItemRemove: function($this, $wishlistTable) {

            $('#wishlist-overlay').addClass('show');

            let	self = this,
                $thisTableRow = $this.closest('.table-body-wrapper'),
                productId = $thisTableRow.data('product-id');
            
            $thisTableRow.addClass('removing');
            
            let productIdIndex = $.inArray(productId, self.wishlistIds);
            if (productIdIndex != -1) {
                self.wishlistIds.splice(productIdIndex, 1);
            }

            self.IDsUpdate();

            setTimeout(function() {
                $thisTableRow.remove();

                self.$body.trigger('wishlist_removed_item');

                if ($wishlistTable.children('.table-body-wrapper').length == 0) {
                    $('#wishlist').css('display', 'none');
                    $('#wishlist-empty').addClass('show');
                }

                $('#wishlist-overlay').removeClass('show');
            }, 500);

        },

        hideWishlistWidgetPopup: function() {
            let self = this;
            clearTimeout(self.autoHideNotify);
            document.querySelector('.notify-container').classList.remove('show');
            return;
        },

        showWishlistWidgetPopup: function() {
            let self = this;
            const doc = document;
            clearTimeout(self.autoHideNotify);
            if(doc.querySelector('.notify-container')) {
                const wishlistWidgetPopup = doc.querySelector('.notify-container');
                self.autoHideNotify = setTimeout(self.hideWishlistWidgetPopup, 5000);

                wishlistWidgetPopup.classList.add('show');
                () => self.autoHideNotify;

                //Events
                const clickEv = (e) => {
                    if(!wishlistWidgetPopup.contains(e.target) && !e.target.classList.contains('wishlist-button') && wishlistWidgetPopup.classList.contains('show')) {
                        clearTimeout(self.autoHideNotify)
                        self.hideWishlistWidgetPopup();
                        doc.removeEventListener("keydown", keydownEv); 
                        if(self.closeBtnPopup) {
                            self.closeBtnPopup.removeEventListener("click", closeBtnEv);
                        }
                        wishlistWidgetPopup.removeEventListener('mouseenter', mouseenterEv);
                        wishlistWidgetPopup.removeEventListener('mouseleave', mouseleaveEv);
                    }
                };
                const keydownEv = (e) => {
                    if(e.key === "Escape" && wishlistWidgetPopup.classList.contains('show')) {
                        clearTimeout(self.autoHideNotify)
                        doc.removeEventListener("click", clickEv);
                        if(self.closeBtnPopup) {
                            self.closeBtnPopup.removeEventListener("click", closeBtnEv);
                        }
                        wishlistWidgetPopup.removeEventListener('mouseenter', mouseenterEv);
                        wishlistWidgetPopup.removeEventListener('mouseleave', mouseleaveEv);
                        self.hideWishlistWidgetPopup();
                    }
                    return;
                }
                const closeBtnEv = (e) => {
                    e.preventDefault();
                    clearTimeout(self.autoHideNotify)
                    self.hideWishlistWidgetPopup();
                    doc.removeEventListener("keydown", keydownEv); 
                    doc.removeEventListener("click", clickEv);
                    wishlistWidgetPopup.removeEventListener('mouseenter', mouseenterEv);
                    wishlistWidgetPopup.removeEventListener('mouseleave', mouseleaveEv);
                }
                const mouseenterEv = () => {clearTimeout(self.autoHideNotify)};
                const mouseleaveEv = () => {
                    self.autoHideNotify = setTimeout(self.hideWishlistWidgetPopup, 3000);
                    () => self.autoHideNotify;
                };

                wishlistWidgetPopup.addEventListener('mouseenter', mouseenterEv);
                wishlistWidgetPopup.addEventListener('mouseleave', mouseleaveEv);
                doc.addEventListener("keydown", keydownEv, { once: true }); 
                doc.addEventListener("click", clickEv, {once: true});
                if(self.closeBtnPopup) {
                    self.closeBtnPopup.addEventListener("click", closeBtnEv, {once: true});
                }

            }
            return;
        },

        showWishlistWidgetPopupSuccess: function(productId, isAdding) {
            let self = this;
           
            self.popupSuccesRes = $.ajax({
                type: 'POST',
                url: wishlist_vars.ajaxUrl,
                data: { 
                    action: 'wishlist_notify',
                    productId: isAdding,
                    isAdding: productId,
                },
                dataType: 'html',
                cache: false,
                headers: {'cache-control': 'no-cache'},
                beforeSend: function () {
                    if(self.thisBtnToggle) {
                    self.thisBtnToggle.disabled = true;
                    }
                },                
                success: function(response) {
                    const ajaxResponse = response;
                    $('#notify-inner').html(ajaxResponse);
                     if(self.thisBtnToggle) {
                    self.thisBtnToggle.disabled = false;
                    self.popupSuccesRes = false;
                    self.showWishlistWidgetPopup();
                }
                },
                error: function(error) {
                    console.log(error);
                },
            });
        },

        showWishlistWidgetPopupLogin: function() {
            let self = this;
            self.showWishlistWidgetPopup();
        }

	}

	$(function() {
		Wishlist.init();
	});
	
})(jQuery);